// #region USERS
export const USER_TYPE = {
  AdminsEgypt: "UTT-12300", // AdminsEgypt أدمنز إيجيبت
  MasterAdmin: "UTT-12400", // MasterAdmin مالك النظام
  Employee: "UTT-12500", // Employee موظف
  Student: "UTT-13000", // Student طالب
};
// #endregion USERS

// #region ActivationType
export const ACTIVATION_TYPE = {
  Active: "AST-17400",
  Archived: "AST-17500",
  Blocked: "UTTAST-17600",
};
// #endregion ActivationType

//#region MEDIA
export const MEDIA_TYPE_TOKENS = {
  image: "MET-1",
  video: "MET-2",
  audio: "MET-3",
  pdf: "MET-4",
  excel: "MET-5",
  word: "MET-6",
  powerPoint: "MET-7",
};

export const MEDIA_EXTENSIONS = {
  image: [
    "jpeg",
    "jpg",
    "png",
    "gif",
    "tiff",
    "psd",
    "tiff",
    "eps",
    "ai",
    "aidd",
    "raw",
  ],
  video: [
    "mov",
    "avi",
    "wmv",
    "mp4",
    "m4p",
    "m4v",
    "mpg",
    "mp2",
    "mpeg",
    "mpe",
    "mpv",
    "3gp",
    "flv",
  ],
  audio: ["aif", "cda", "mid", "mp3", "mpa", "ogg", "wav", "wma", "wpl"],
  pdf: ["pdf"],
  excel: ["xlsx", "xlsm", "xlsb", "xltx", "xls"],
  word: ["doc", "docm", "docx"],
  powerPoint: ["ppsx", "ppt", "pptm", "pptx"],
};
//#endregion MEDIA
