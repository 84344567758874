<template>
  <nav class="navbar-login" ref="navbarLogin">
    <div class="project-name">
      <router-link :to="{ name: 'Home' }">
        <img src="@/assets/images/logo.png" />
        <h3>{{ $t("projectName") }}</h3>
      </router-link>
    </div>
    <div class="login-icon"></div>
    <div class="lang-icon"></div>
  </nav>
</template>

<script>
export default {
  name: "NavbarLogin",
  data() {
    return {};
  },
  methods: {},
  computed: {},
  mounted() {},
  watch: {},
};
</script>

<style lang="scss"></style>
