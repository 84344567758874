export const BASE_URL = "https://api-pls-dev.premcoprecast.com";

const map_URL =
  "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d55294.46612452538!2d31.154925!3d29.982185!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x145845efbf7f0e15%3A0xedcee3eb071f4249!2s1%20El-Orouba%2C%20Nazlet%20Al%20Batran%2C%20El%20Talbia%2C%20Giza%20Governorate%2C%20Egypt!5e0!3m2!1sen!2sus!4v1646587049743!5m2!1sen!2suss";
const map_STATUS = true;
const faceBook_URL = "https://www.google.com";
const faceBook_STATUS = true;
const twitter_URL = "https://www.google.com";
const twitter_STATUS = true;
const linkedin_URL = "https://www.google.com";
const linkedin_STATUS = true;
const whatsApp_URL = "https://www.google.com";
const whatsApp_STATUS = true;
const youTube_URL = "https://www.google.com";
const youTube_STATUS = true;

const projectAddress1_STATUS = true;
const projectPhone1_STATUS = true;
const projectPhone2_STATUS = true;
const projectPhone3_STATUS = false;
const projectEmail1_STATUS = true;
const projectEmail2_STATUS = false;

export const SOCIAL = {
  map_URL,
  map_STATUS,
  faceBook_URL,
  faceBook_STATUS,
  twitter_URL,
  twitter_STATUS,
  linkedin_URL,
  linkedin_STATUS,
  whatsApp_URL,
  whatsApp_STATUS,
  youTube_URL,
  youTube_STATUS,
};
export const BASIC_CONTACTS = {
  projectAddress1_STATUS,
  projectPhone1_STATUS,
  projectPhone2_STATUS,
  projectPhone3_STATUS,
  projectEmail1_STATUS,
  projectEmail2_STATUS,
};

export const STATUS = {
  SUCCESS: 200, // success operation
  INVALID_TOKEN: 498, //invalid token
  CATCH: 500, // catch
  NO_CONTENT: 204, // no data
  ERROR: 406, // validation
  VERSION: 306, // error version
};

//#region DefaultPages & Languages
export const DEFAULT_PAGES = {
  notLoggedIn: "Home",
  loggedIn: "ActivityTypes",
};

// ##duplicated in functions file
export const LANGUAGES = {
  list: ["ar-EG", "en-US", "und"],
  default: "ar-EG",
  arEG: "ar-EG",
  enUS: "en-US",
  und: "und",
  defaultCountryCode: "+20",
  defaultCountryCodeName: "EG",
};
//#endregion DefaultPages & Languages

//#region layoutDisplaies
export const LAYOUT_DISPLAY = {
  default: "table",
  list: ["table", "cards"],
};
//#endregion layoutDisplaies

export const ESTABLISHMENT_TOKEN = "f92dfb74-9cb9-44ad-b5ad-a946c3d1018f";

export const USER_PLATFORM_SESSION_VERSION_NUMBER = "1.0";

export const PAGE_SIZE = 50;
export const IMPOSSIBLE_TOKEN = 0;
export const NAME_MIN_LENGTH = 3;
export const NAME_MAX_LENGTH = 50;
export const TEXT_MAX_LENGTH = 500;
export const TEXT_MAX_LENGTH_SUPER = 1000000;
export const MAX_ROWS_TEXTAREA = 100;
