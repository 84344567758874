import i18n from "./../i18n";

const routes = [
  //#region General

  {
    path: "/",
    name: "Home",
    component: () => import("../views/home/Home.vue"),
    meta: {
      pageName: i18n.t("homePage"),
      pageIcon: "homepage.svg",
      currentPage: "home",
      currentSubPage: "",
    },
  },
  {
    path: "/car/info",
    name: "Info",
    component: () => import("../views/home/Info.vue"),
    props(route) {
      return route.params || {};
    },
    meta: {
      pageName: i18n.t("info"),
      pageIcon: "info.svg",
      currentPage: "info",
      currentSubPage: "",
    },
  },
  //#endregion General
];

export default routes;
