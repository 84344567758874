<template>
  <div id="app">
    <div class="page-navbar">
      <NavbarLogin />
    </div>

    <div class="page-sidebar">
      <Sidebar v-if="false" />
    </div>

    <div class="page-content">
      <router-view class="page-content-container p-3" />
    </div>
  </div>
</template>

<script>
import NavbarLogin from "@/components/general/NavbarLogin.vue";

import Sidebar from "@/components/general/Sidebar.vue";
// // import Footer from "@/components/general/Footer.vue";

export default {
  name: "App",
  components: {
    NavbarLogin,
    Sidebar,
    // Footer,
  },
  data() {
    return {
      language: "ar",
      rootStyle: document.documentElement.style,
    };
  },
  methods: {
    setProjectNameTitle() {
      document.title = this.$t("projectName");
    },
    setRootStyle() {
      if (this.language == "en-US") {
        this.rootStyle.setProperty("--position-start", "left");
        this.rootStyle.setProperty("--position-end", "right");
        this.rootStyle.setProperty("--direction", "ltr");
        this.rootStyle.setProperty("--font-family", "sans-serif");
        this.rootStyle.setProperty("--display-btn-en", "none");
        this.rootStyle.setProperty("--display-btn-ar", "block");
        this.rootStyle.setProperty("--position-left-status", "auto");
        this.rootStyle.setProperty("--position-right-status", "0");
        this.rootStyle.setProperty("--fab-container-left", "auto");
        this.rootStyle.setProperty("--fab-container-right", "15px");
        this.rootStyle.setProperty("--selection-dropdown-left", "auto");
        this.rootStyle.setProperty("--selection-dropdown-right", "1rem");
      } else {
        this.rootStyle.setProperty("--position-start", "right");
        this.rootStyle.setProperty("--position-end", "left");
        this.rootStyle.setProperty("--direction", "rtl");
        this.rootStyle.setProperty("--font-family", "arabic");
        this.rootStyle.setProperty("--display-btn-en", "block");
        this.rootStyle.setProperty("--display-btn-ar", "none");
        this.rootStyle.setProperty("--position-left-status", "0");
        this.rootStyle.setProperty("--position-right-status", "auto");
        this.rootStyle.setProperty("--fab-container-left", "15px");
        this.rootStyle.setProperty("--fab-container-right", "auto");
        this.rootStyle.setProperty("--selection-dropdown-left", "1rem");
        this.rootStyle.setProperty("--selection-dropdown-right", "auto");
      }
    },
  },
  computed: {},
  async created() {
    this.setProjectNameTitle();
    this.setRootStyle();
  },
};
</script>

<style lang="scss"></style>
