<template>
  <ul>
    <!-- #region Users -->
    <li class="sub-menu">
      <a v-b-toggle.users :class="currentPage == 'users' ? 'active' : ''">
        <span class="icon"><img src="@/assets/images/users.svg" /></span>
        <span class="title">
          {{ $t("Users.modelName") }}
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </span>
      </a>
      <b-collapse id="users">
        <ul>
          <li>
            <router-link
              :to="{ name: 'Users' }"
              :class="currentSubPage == 'students' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/students.svg"
              /></span>
              <span class="title">{{ $t("students.modelName") }}</span>
            </router-link>
          </li>
          <!-- <li>
            <router-link
              :to="{ name: 'Employees' }"
              :class="currentSubPage == 'employees' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/employees.svg"
              /></span>
              <span class="title">{{ $t("employees.modelName") }}</span>
            </router-link>
          </li>

          <li>
            <router-link
              :to="{ name: 'MasterAdmins' }"
              :class="currentSubPage == 'masterAdmins' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/masterAdmins.svg"
              /></span>
              <span class="title">{{ $t("masterAdmins.modelName") }}</span>
            </router-link>
          </li> -->
        </ul>
      </b-collapse>
    </li>
    <!-- #endregion Users -->

    <!-- #region Settings -->
    <li class="sub-menu">
      <a
        v-b-toggle.theSettings
        :class="currentPage == 'theSettings' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/settings.svg" /></span>
        <span class="title">
          {{ $t("settings") }}
          <i class="fa fa-angle-down" aria-hidden="true"></i>
        </span>
      </a>
      <b-collapse id="theSettings">
        <ul>
          <li>
            <router-link
              :to="{ name: 'Settings' }"
              :class="currentSubPage == 'settings' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/settings.svg"
              /></span>
              <span class="title">{{ $t("settings") }}</span>
            </router-link>
          </li>
          <!-- #region SettingsOther -->
          <li>
            <router-link
              :to="{ name: 'SettingsOthers' }"
              :class="currentSubPage == 'settings-others' ? 'active' : ''"
            >
              <span class="icon"
                ><img src="@/assets/images/settings-other.svg"
              /></span>
              <span class="title">{{ $t("settings-other") }}</span>
            </router-link>
          </li>
          <!-- #endregion SettingsOther -->
        </ul>
      </b-collapse>
    </li>
    <!-- #endregion Settings -->

    <!-- //#region ModulePrivilege -->
    <li>
      <router-link
        :to="{ name: 'Privileges' }"
        :class="currentPage == 'privileges' ? 'active' : ''"
      >
        <span class="icon"><img src="@/assets/images/privileges.svg" /></span>
        <span class="title">{{ $t("Privileges.modelName") }}</span>
      </router-link>
    </li>
    <!-- #endregion ModulePrivilege -->
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
import { checkPrivilege } from "@/utils/functions";

export default {
  computed: {
    ...mapGetters(["usersPrivilegeData"]),
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar-EG",
      currentPage: "",
      currentSubPage: "",
    };
  },
  methods: {
    getPageInfo() {
      this.currentPage = this.$route.meta.currentPage || "";
      this.currentSubPage = this.$route.meta.currentSubPage || "";
    },
    checkPrivilege,
  },
  watch: {
    $route() {
      this.getPageInfo();
    },
  },
  async created() {
    this.getPageInfo();
  },
};
</script>
